import axios from 'axios';
import { api } from '../../config'
import { getHeaders } from './localStorage'
import errorHandling from './errorHandling'
import { queryString } from '../../utils/String'

export const getCourses = (params) => async (dispatch) => {
    try {
        if (params.query === 'prev') {
            params.sort = '-1';
        }
        const response = await axios.get(api + `/courses?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_COURSES", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteCourse = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api + "/courses/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewCourse = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/courses/${id}`, getHeaders());
        dispatch({ type: 'GET_COURSE', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddCourse = (props) => async () => {
    try {
        const response = await axios.post(api + "/courses", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const updateSubsCourse = (props) => async (dispatch) => {
    try {
        const id = props.id;
        delete props.id;
        await axios.put(api + "/course-student/" + id, props, getHeaders());
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleUpdateCourse = (props) => async () => {
    try {
        const response = await axios.put(api + "/courses/" + props._id, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleAddCourseStudent = (props) => async () => {
    try {
        const response = await axios.post(api + "/course-student", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getCoursesSubscribed = (id) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/course-student/${id}`, getHeaders());
        dispatch({ type: "GET_COURSES_SUBSCRIBED", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getSubs = (params) => async (dispatch) => {
    try {
        const id = params.id;
        delete params.id;
        const response = await axios.get(api + `/course-student-course/${id}?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_SUBS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearCourse = () => {
    return function (dispatch) {
        dispatch({ type: "CLEAR_COURSE" })
    }
}

export const clearCourses = () => {
    return function (dispatch) {
        dispatch({ type: "CLEAR_COURSES" })
    }
}





